import * as React from 'react';
import { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import SmsIcon from '@mui/icons-material/Sms';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import GradeIcon from '@mui/icons-material/Grade';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import StorageIcon from '@mui/icons-material/Storage';
import BarChartIcon from '@mui/icons-material/BarChart';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AddCardIcon from '@mui/icons-material/AddCard';
import HomeIcon from '@mui/icons-material/Home';
import { Typography } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';

import Cap from '../Cap';
import Networks from '../Cap/Networks';
import Settings from '../Settings';
import Unic from '../Unic';
import Editor from '../Editor';
import Fbservice from '../Fbservice';
import Cloaker from '../Cloaker';
import Home from '../Home';
import mainAPI from '../../utils/mainAPI';
import useStore from '../../hooks/useStore';
import Users from '../Users';
import EPCChart from '../EPCChart';
import FAQ from '../FAQ';
import CRM from '../CRM';
import Farm from '../Farm';
import ORD from '../ORD';
import Platforms from '../ORD/Platforms';
import Invoice from '../ORD/Invoice';
import Creative from '../ORD/Creative';
import Organization from '../ORD/Organization';
import Contract from '../ORD/Contract';
import CardsRent from '../CardsRent';
import Sms from '../Sms';
// import Database from "../Database";
// import Stats from "../Stats";

import img from './pony.png';
import './home_background.jpg';
import './style.scss';
import GoogleMonitoring from '../GoogleMonitoring';
import FraudAnalysisForm from '../AI-Antifraud';
import { BallotOutlined } from '@mui/icons-material';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: 'rgb(0 0 0 / 0%)',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background: '#1b1b1b',
  border: 'none',
  width: `60px`,
  [theme.breakpoints.up('sm')]: {
    width: `60px`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const date = new Date();
const today = date.getDate();

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function logout() {
  mainAPI.post('primary/logout').then((res) => {
    window.location.reload();
  });
}

export default function Main() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [themeChecked, setThemeChecked] = useState(false);
  const [showPony, setShowPony] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  let location = useLocation();

  const { appStore } = useStore();
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const userLogin = appStore.getUserName();

  useEffect(() => {
    let total = '';
    window.addEventListener('keydown', (e) => {
      total += e.key;
      if (total.indexOf('magicplease') !== -1) {
        setShowPony(true);
      }
    });

    if (appStore.appTheme === 'dark') {
      setThemeChecked(true);
    }
  }, []);

  function switchTheme() {
    if (appStore.appTheme === 'dark') {
      appStore.setAppTheme('light');
      localStorage.setItem('theme', 'light');
    } else {
      appStore.setAppTheme('dark');
      localStorage.setItem('theme', 'dark');
    }
    setThemeChecked(!themeChecked);
  }

  const userRoles = appStore.getUserRoles();
  const rolesArr = ['buyer', 'farmer', 'admin'];

  const whoCanViewGoogleMonitoring = userRoles.some((role) => rolesArr.includes(role));

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer variant='permanent' open={open}>
        <DrawerHeader sx={{ justifyContent: 'center' }}>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        {userLogin === 'antonTeam' ? (
          <List>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/'>
              <ListItem button>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary='Главная' />
              </ListItem>
            </Link>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/pixels'>
              <ListItem button>
                <ListItemIcon>
                  <VpnKeyIcon />
                </ListItemIcon>
                <ListItemText primary='Пиксели' />
              </ListItem>
            </Link>
          </List>
        ) : (
          <List>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/'>
              <ListItem button>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary='Главная' />
              </ListItem>
            </Link>

            <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/crm'>
              <ListItem button>
                <ListItemIcon>
                  <Typography sx={{ fontWeight: 'bold' }}>CRM</Typography>
                </ListItemIcon>
                <ListItemText primary='CRM' />
              </ListItem>
            </Link>

            {appStore.getUserRoles().indexOf('buyer') !== -1 ? (
              <>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/caps'>
                  <ListItem button>
                    <ListItemIcon>
                      <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary='Капы' />
                  </ListItem>
                </Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/pixels'>
                  <ListItem button>
                    <ListItemIcon>
                      <VpnKeyIcon />
                    </ListItemIcon>
                    <ListItemText primary='Пиксели' />
                  </ListItem>
                </Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/cloaker'>
                  <ListItem button>
                    <ListItemIcon>
                      <GradeIcon />
                    </ListItemIcon>
                    <ListItemText primary='Клоакер' />
                  </ListItem>
                </Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/faq'>
                  <ListItem button>
                    <ListItemIcon>
                      <QuizIcon />
                    </ListItemIcon>
                    <ListItemText primary='FAQ' />
                  </ListItem>
                </Link>
              </>
            ) : null}

            <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/farm'>
              <ListItem button>
                <ListItemIcon>
                  <GroupAddIcon />
                </ListItemIcon>
                <ListItemText primary='Аренда аккаунтов' />
              </ListItem>
            </Link>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/cards'>
              <ListItem button>
                <ListItemIcon>
                  <AddCardIcon />
                </ListItemIcon>
                <ListItemText primary='Аренда карт' />
              </ListItem>
            </Link>

            {whoCanViewGoogleMonitoring && (
              <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/google'>
                <ListItem button>
                  <ListItemIcon>
                    <GoogleIcon />
                  </ListItemIcon>
                  <ListItemText primary='Google Monitoring' />
                </ListItem>
              </Link>
            )}
            {(appStore.getUserRoles().includes('sms_admin') || appStore.getUserRoles().includes('sms_payoneer')) && (
              <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/sms'>
                <ListItem button>
                  <ListItemIcon>
                    <SmsIcon />
                  </ListItemIcon>
                  <ListItemText primary='SMS' />
                </ListItem>
              </Link>
            )}
            {(appStore.getUserRoles().includes('audit') || appStore.getUserRoles().includes('admin')) && (
              <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/fraud'>
                <ListItem button>
                  <ListItemIcon>
                    <BallotOutlined />
                  </ListItemIcon>
                  <ListItemText primary='AI-Antifraud' />
                </ListItem>
              </Link>
            )}
            {appStore.getUserRoles().indexOf('admin') !== -1 ? (
              <>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/ord'>
                  <ListItem button>
                    <ListItemIcon>ОРД</ListItemIcon>
                    <ListItemText primary='ORD' />
                  </ListItem>
                </Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/users'>
                  <ListItem button>
                    <ListItemIcon>
                      <GroupAddIcon />
                    </ListItemIcon>
                    <ListItemText primary='Пользователи' />
                  </ListItem>
                </Link>
              </>
            ) : null}
          </List>
        )}

        <List style={{ height: '100%' }} />

        <List>
          <ListItem button onClick={logout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary='Выход' />
          </ListItem>
        </List>
      </Drawer>

      <Box component='main' id='main' sx={{ flexGrow: 1, p: 3 }}>
        {showPony ? (
          <>
            <img
              style={{
                position: 'fixed',
                height: '150px',
                bottom: '20px',
                right: '20px',
              }}
              src={img}
              alt=''
            />
          </>
        ) : null}
        <TransitionGroup>
          <CSSTransition
            key={location.pathname.includes('faq') ? null : location.pathname}
            classNames='page'
            timeout={600}
            transitionEnter={false}
            transitionLeave={false}
          >
            <div className='page'>
              <Routes location={location}>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/caps' element={<Cap />} />
                <Route exact path='/cloaker' element={<Cloaker />} />
                <Route exact path='/faq' element={<FAQ />} />
                {whoCanViewGoogleMonitoring && <Route exact path='/google' element={<GoogleMonitoring />} />}
                <Route exact path='/cards' element={<CardsRent />} />
                <Route path='/faq/:url' element={<FAQ match={location.pathname} />} />
                <Route exact path='/unic' element={<Unic />} />
                <Route exact path='/pixels' element={<Fbservice />} />
                <Route exact path='/settings' element={<Settings />} />
                <Route exact path='/editor' element={<Editor />} />
                {(appStore.getUserRoles().includes('sms_admin') ||
                  appStore.getUserRoles().includes('sms_payoneer')) && (
                  <>
                    <Route exact path='/sms' element={<Sms />} />
                  </>
                )}
                {(appStore.getUserRoles().includes('audit') || appStore.getUserRoles().includes('admin')) && (
                  <Route exact path='/fraud' element={<FraudAnalysisForm />} />
                )}
                <Route exact path='/crm' element={<CRM user={userLogin} />} />
                <Route exact path='/crm/:id' element={<CRM user={userLogin} />} />
                <Route exact path='/farm' element={<Farm />} />
                {appStore.getUserRoles().includes('admin') ? (
                  <>
                    <Route path='/ord' element={<ORD />}>
                      <Route path='platforms' element={<Platforms />} />
                      <Route path='contracts' element={<Contract />} />
                      <Route path='organizations' element={<Organization />} />
                      <Route path='creatives' element={<Creative />} />
                      <Route path='invoices' element={<Invoice />} />
                    </Route>

                    <Route exact path='/users' element={<Users />} />
                  </>
                ) : null}
                {appStore.getUserRoles().includes('admin_caps') || appStore.getUserRoles().includes('admin') ? (
                  <>
                    <Route exact path='/caps/networks' element={<Networks />} />
                  </>
                ) : null}
                <Route exact path='*' element={<Home />} />
              </Routes>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Box>
    </Box>
  );
}
