import {
  Button,
  Card,
  Container,
  Modal,
  Skeleton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  NativeSelect,
} from '@mui/material';
import CapItem from './Item/CapItem';
import React, { useEffect, useState } from 'react';
import mainAPI from '../../utils/mainAPI';
import AddCapItem from './Add';
import ChangeCapItem from './Change';
import useStore from '../../hooks/useStore';
import DatePicker from 'react-date-picker';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'react-date-picker/dist/DatePicker.css';

export default function Cap() {
  const [loadedData, setLoadedData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);
  const handleOpenChangeModal = () => setOpenChangeModal(true);
  const handleCloseChangeModal = () => setOpenChangeModal(false);
  const [daysData, setDaysData] = useState([]);
  const { appStore } = useStore();
  const [changedData, setChangedData] = useState([]);
  const [chartValues, setChartValues] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const today = new Date();
  const [selectedDay, setSelectedDay] = useState(today);
  const [loadedNetworks, setLoadedNetworks] = useState([]);
  const [stavkaModal, setStavkaModal] = useState(false);
  const [stavkaChoose, setStavkaChoose] = useState('BINOM V2');
  const [stavkaNetwork, setStavkaNetwork] = useState('');
  const [stavkaId, setStavkaId] = useState('');
  const [stavkaGeo, setStavkaGeo] = useState('');
  const [stavkaValue, setStavkaValue] = useState('');
  const [stavkaReason, setStavkaReason] = useState('');
  const [stavkaEnabled, setStavkaEnabled] = useState(true);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    document.title = '2leads Dev - Капы';

    mainAPI
      .get('/bot/weeklycaps')
      .then((res) => {
        setDaysData(res.data);
      })
      .catch((e) => {
        console.error('Error loading weekly caps:', e);
      });

    mainAPI.get('/bot/networks').then((res) => {
      const networks = [];

      res.data.forEach((item) => {
        networks.push({
          name: item.name,
          id: item.id,
        });
      });
      setLoadedNetworks(networks);
    });
  }, []);

  useEffect(() => {
    if (convertDate(selectedDay) === convertDate(new Date())) {
      mainAPI.get('bot/allbotrules').then((res) => {
        setLoadedData(res.data);
      });
    } else {
      mainAPI.get('bot/daycaps?' + convertDate(selectedDay)).then((res) => {
        setLoadedData(res.data);
      });
    }
  }, [selectedDay]);

  function updateStavka() {
    setStavkaEnabled(false);
    mainAPI
      .post('/bot/updatestavka', {
        choose: stavkaChoose,
        id: stavkaId,
        geo: stavkaGeo,
        value: stavkaValue,
        reason: stavkaReason,
        name: stavkaNetwork,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success('Успешно');
          setStavkaEnabled(true);
        }
      })
      .catch((e) => {
        toast.error('ошибка');
        setStavkaEnabled(true);
      });
  }

  function convertDate(date) {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return (
      'year=' + yyyy + '&month=' + (mmChars[1] ? mm : '0' + mmChars[0]) + '&day=' + (ddChars[1] ? dd : '0' + ddChars[0])
    );
  }
  function skeleton() {
    return (
      <>
        <Skeleton variant='rect' sx={{ width: '30%', margin: '15px', height: 100 }} />
        <Skeleton variant='rect' sx={{ width: '30%', margin: '15px', height: 100 }} />
        <Skeleton variant='rect' sx={{ width: '30%', margin: '15px', height: 100 }} />
        <Skeleton variant='rect' sx={{ width: '30%', margin: '15px', height: 140 }} />
        <Skeleton variant='rect' sx={{ width: '30%', margin: '15px', height: 140 }} />
        <Skeleton variant='rect' sx={{ width: '30%', margin: '15px', height: 140 }} />
        <Skeleton variant='rect' sx={{ width: '30%', margin: '15px', height: 180 }} />
        <Skeleton variant='rect' sx={{ width: '30%', margin: '15px', height: 180 }} />
        <Skeleton variant='rect' sx={{ width: '30%', margin: '15px', height: 180 }} />
        <Skeleton variant='rect' sx={{ width: '100%', margin: '15px', height: 50 }} />
      </>
    );
  }

  function deleteEl(el) {
    const newData = [];
    loadedData.forEach((item, index) => {
      if (item._id !== el) {
        newData.push(item);
      }
    });
    setLoadedData(newData);
  }

  function createEl() {
    setOpenAddModal(false);
    setLoadedData([]);
    mainAPI.get('bot/allbotrules').then((res) => {
      setLoadedData(res.data);
    });
  }

  function changeEl(el) {
    setChangedData(el);

    setOpenChangeModal(true);
  }

  function closeChangeEl() {
    setOpenChangeModal(false);
    setLoadedData([]);
    mainAPI.get('bot/allbotrules').then((res) => {
      setLoadedData(res.data);
    });
  }

  function prepareCapsData(data) {
    // складываем в обькет по имени рекла
    const capsRulesObject = {};
    data.forEach((item) => {
      if (capsRulesObject[item.network] === undefined) {
        capsRulesObject[item.network] = [item];
      } else {
        capsRulesObject[item.network] = [...capsRulesObject[item.network], item];
      }
    });

    // делаем массив и сортируем по количеству
    const capsRulesArray = [];

    Object.keys(capsRulesObject).forEach((item) => {
      capsRulesArray.push({
        network: item,
        data: capsRulesObject[item],
      });
    });

    capsRulesArray.sort((a, b) => {
      if (a.data.length > b.data.length) {
        return -1;
      }
    });

    const result = [];
    capsRulesArray.forEach((cap) => {
      result.push(
        <CapItem
          networks={loadedNetworks}
          disableEvents={selectedDay == today}
          key={cap.data._id}
          change={changeEl}
          del={deleteEl}
          data={cap.data}
          daysData={daysData}
        />
      );
    });

    return result;
  }

  function changeDay(day) {
    setSelectedDay(day);
    if (day === 'today') {
      mainAPI.get('bot/allbotrules').then((res) => {
        setLoadedData(res.data);
      });
    } else {
      mainAPI.get('bot/prevdaycaps').then((res) => {
        setLoadedData(res.data);
      });
    }
  }

  return (
    <>
      <Typography variant='h4' style={{ textAlign: 'left', marginLeft: 50, marginBottom: '14px' }}>
        Капы
      </Typography>
      <Container sx={{ display: 'flex', marginBottom: '40px' }}>
        <DatePicker value={selectedDay} onChange={setSelectedDay} />
        {appStore.getUserRoles().indexOf('admin') !== -1 || appStore.getUserRoles().indexOf('admin_caps') !== -1 ? (
          <>
            <Link
              style={{
                textDecoration: 'none',
                marginLeft: '30px',
                color: 'inherit',
              }}
              to='/caps/networks'
            >
              <Button>Реклы</Button>
            </Link>
            <Button
              onClick={() => {
                setStavkaModal(true);
              }}
            >
              Обновить ставку
            </Button>
            <Button onClick={setOpenAddModal}>Добавить</Button>
          </>
        ) : null}
      </Container>

      <Container
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {loadedData.length > 0 ? <>{prepareCapsData(loadedData)}</> : skeleton()}
      </Container>

      <Modal open={openAddModal} onClose={handleCloseAddModal}>
        <Card sx={style}>
          <AddCapItem close={createEl} networks={loadedNetworks} />
        </Card>
      </Modal>
      <Modal
        open={openChangeModal}
        onClose={handleCloseChangeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <ChangeCapItem close={closeChangeEl} networks={loadedNetworks} data={changedData} />
        </Card>
      </Modal>
      <Dialog
        open={stavkaModal}
        onClose={() => {
          setStavkaModal(false);
          setStavkaChoose('BINOM V2');
          setStavkaNetwork('');
          setStavkaGeo('');
          setStavkaId('');
          setStavkaValue('');
          setStavkaReason('');
        }}
        aria-labelledby='watchtoken-title'
        aria-describedby='watchtoken-description'
      >
        <DialogTitle id='watchtoken-slide-title'>Обновление ставки</DialogTitle>
        <DialogContent sx={{ minWidth: '300px', display: 'flex', flexDirection: 'column' }}>
          <NativeSelect
            defaultValue={stavkaChoose}
            inputProps={{
              name: 'age',
              id: 'uncontrolled-native',
            }}
            onChange={(e) => {
              setStavkaChoose(e.target.value);
            }}
          >
            <option value='BINOM V2'>BINOM V2</option>
          </NativeSelect>
          <NativeSelect
            value={stavkaNetwork || ''}
            label='Имя рекла'
            style={{ marginTop: '10px' }}
            onChange={(e) => {
              setStavkaNetwork(e.target.value);
            }}
          >
            <option value='' disabled>
              Выберите рекла
            </option>
            {loadedNetworks
              .filter((item) => item.name)
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((item) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
          </NativeSelect>
          <TextField
            value={stavkaId}
            onChange={(e) => {
              setStavkaId(e.target.value);
            }}
            label='id рекла'
            placeholder='2'
            style={{ marginTop: '10px' }}
          />
          <TextField
            value={stavkaGeo}
            onChange={(e) => {
              setStavkaGeo(e.target.value);
            }}
            label='Гео'
            placeholder='it'
            style={{ marginTop: '10px' }}
          />
          <TextField
            value={stavkaValue}
            placeholder='2.5'
            onChange={(e) => {
              setStavkaValue(e.target.value);
            }}
            label='Ставка через точку'
            style={{ marginTop: '10px' }}
          />
          <TextField
            value={stavkaReason}
            placeholder='Просто потому что'
            onChange={(e) => {
              setStavkaReason(e.target.value);
            }}
            label='Причина'
            style={{ marginTop: '10px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={!stavkaEnabled} variant='outlined' onClick={updateStavka}>
            Обновить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
