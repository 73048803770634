import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useStore from '../../../../hooks/useStore';
import mainAPI from '../../../../utils/mainAPI';
import {
  IconButton,
  Paper,
  Button,
  Typography,
  Pagination,
  Popover,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TableSortLabel,
  Switch,
  FormControlLabel,
  Tooltip,
} from '@mui/material';

import { Replay, Download, Warning } from '@mui/icons-material';

import { CustomSkeleton, DebounceInput } from '../../components';

import css from './streamsAdspect.module.scss';

export const StreamsAdspect = () => {
  const modes = {
    Filter: 'Фильтр',
    Review: 'Модерация',
    Money: 'Контент',
    White: 'Белая страница',
  };

  const [loading, setLoading] = useState(true);
  const [noLoading, setNoLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [streams, setStreams] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [streamsPerPage, setStreamsPerPage] = useState(20);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updated_at');
  const [customStreamModes, setCustomStreamModes] = useState({});
  const [anchorEls, setAnchorEls] = useState({});

  const [csvLoading, setCsvLoading] = useState(false);

  const { appStore } = useStore();
  const user = appStore.userRoles.includes('admin') ? '' : appStore.userLogin;

  useEffect(() => {
    const controller = new AbortController();

    loadStreams(controller);

    return () => {
      controller.abort();
    };
  }, [search, currentPage, streamsPerPage, order, orderBy, user]);

  const loadStreams = async (controller = null) => {
    setLoading(true);
    try {
      const response = await mainAPI.get(`/cloaker/getstreams/${user}`, {
        params: {
          order,
          orderBy,
          search,
          page: currentPage,
          perPage: streamsPerPage,
        },
        signal: controller?.signal,
      });
      const { data, total } = response.data;

      const modes = {};
      data.forEach((stream) => {
        modes[stream.stream_id] = stream.customMode === 'auto' ? 'auto' : 'manual';
      });
      setCustomStreamModes(modes);
      setStreams(data);
      setTotal(total);
      setNoLoading(false);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Запрос был отменен');
        return;
      }
      console.log('Не удалось загрузить потоки', error);
      setNoLoading(true);
    } finally {
      setLoading(false);
    }
  };

  const changeMode = async (id, mode) => {
    try {
      await mainAPI.patch(`/cloaker/changemode/${id}/${mode}`);
      toast.success('Успешно');
      loadStreams();
    } catch (error) {
      toast.error('Произошла ошибка при смене режима', error);
    }
  };

  const handleStreamModeChange = async (streamId, countries) => {
    const currentMode = customStreamModes[streamId];
    const newMode = currentMode === 'auto' ? 'manual' : 'auto';

    try {
      await mainAPI.patch(`/cloaker/changecustommode/${streamId}`, {
        custommode: newMode,
        countries: countries || [],
      });

      setCustomStreamModes((prevModes) => ({
        ...prevModes,
        [streamId]: newMode,
      }));
      toast.success('Режим успешно изменён');
    } catch (error) {
      console.error('Ошибка при смене режима:', error);
      toast.error('Ошибка при смене режима');
    }
  };

  // const deleteStream = async (id) => {
  //   try {
  //     await mainAPI.delete(`/cloaker/deletestream/${id}`);
  //     toast.success('Успешно');
  //     setDeleteDialog(false);
  //     loadStreams();
  //   } catch (error) {
  //     toast.error('Произошла ошибка при удалении потока', error);
  //   }
  // };

  const handleDebounce = (value) => {
    setSearch(value);
  };

  const handlePageChange = (event, value) => setCurrentPage(value);

  const handleStreamsPerPageChange = (event) => {
    setStreamsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleMenuOpen = (event, streamId) => {
    setAnchorEls((prev) => ({ ...prev, [streamId]: event.currentTarget }));
  };

  const handleMenuClose = (streamId) => {
    setAnchorEls((prev) => ({ ...prev, [streamId]: null }));
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const exportStreams = () => {
    setCsvLoading(true);
    const exportPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await mainAPI.get(`/cloaker/exportstreamscsv/${user}`, {
          responseType: 'blob',
        });

        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        const contentDisposition = response.headers['content-disposition'];
        const fileName = contentDisposition
          ? contentDisposition.split('filename=')[1]?.replace(/"/g, '')
          : 'all_streams.xlsx';

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        resolve('Файл успешно скачан');
      } catch (error) {
        console.error('Ошибка:', error);
        reject(error);
      } finally {
        setCsvLoading(false);
      }
    });

    toast.promise(
      exportPromise,
      {
        pending: 'Начат экспорт потоков...',
        success: 'Успешный экспорт потоков',
        error: 'Ошибка при экспорте',
      },
      {
        position: 'top-right',
        autoClose: 3000,
      }
    );
  };

  return (
    <>
      {noLoading && (
        <div className={css.noStreams}>
          <h5>Не удалось загрузить потоки ;(</h5>
        </div>
      )}
      <div className={css.reload}>
        <div className={css.export_streams}>
          <Button
            onClick={() => exportStreams()}
            startIcon={<Download />}
            variant='contained'
            disabled={csvLoading || loading}
            aria-label='export streams'
          >
            Экспорт CSV
          </Button>
        </div>
        <div className={css.reloadControl}>
          <Typography>Обновить список потоков:</Typography>
          <IconButton onClick={loadStreams} aria-label='reload' disabled={loading}>
            <Replay />
          </IconButton>
        </div>
        <div className={css.paginationControl}>
          <FormControl variant='outlined' className={css.formControl}>
            <InputLabel>Потоков на странице</InputLabel>
            <Select value={streamsPerPage} onChange={handleStreamsPerPageChange} label='Потоков на странице'>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={css.search}>
        <DebounceInput placeholder='Поиск потоков' debounceTimeout={500} handleDebounce={handleDebounce} />
      </div>
      {loading && CustomSkeleton()}
      {!loading && !noLoading && (
        <div className={css.sortHeaders}>
          <Button onClick={() => handleRequestSort('name')}>
            <TableSortLabel active={orderBy === 'name'} direction={orderBy === 'name' ? order : 'asc'}>
              Название
            </TableSortLabel>
          </Button>
          <Typography className={css.statusLabel}>Статус</Typography>
          <Button onClick={() => handleRequestSort('updated_at')}>
            <TableSortLabel active={orderBy === 'updated_at'} direction={orderBy === 'updated_at' ? order : 'asc'}>
              Дата изменения
            </TableSortLabel>
          </Button>
          <Button onClick={() => handleRequestSort('mode')}>
            <TableSortLabel active={orderBy === 'mode'} direction={orderBy === 'mode' ? order : 'asc'}>
              Режим
            </TableSortLabel>
          </Button>
        </div>
      )}
      {!loading &&
        streams.map((item, index) => (
          <Paper
            className={css.streamItem}
            variant={index % 2 !== 0 ? 'outlined' : 'elevation'}
            key={item.stream_id}
            sx={{
              ...(item.tags.includes('DELETED') && {
                backgroundColor: 'error.dark',
              }),
            }}
          >
            <Typography className={css.streamName} sx={{ fontWeight: 'bold' }}>
              {item.tags.includes('DELETED') && (
                <Tooltip
                  title='Поток помечен на удаление и/или требует вашего внимания'
                  sx={{ verticalAlign: 'middle', mr: '10px' }}
                >
                  <Warning />
                </Tooltip>
              )}
              {item.name}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={customStreamModes[item.stream_id] === 'auto'}
                  onChange={() => handleStreamModeChange(item.stream_id, item.countries)}
                  color='primary'
                />
              }
              label={customStreamModes[item.stream_id] === 'auto' ? 'Авто' : 'Ручной'}
            />
            <Typography>
              {new Date(item.updated_at * 1000).toLocaleString('ru-RU', { dateStyle: 'short', timeStyle: 'medium' })}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ marginRight: '20px' }}>Режим:</Typography>
              <Button
                sx={{ width: '170px' }}
                variant='contained'
                onClick={(event) => handleMenuOpen(event, item.stream_id)}
              >
                {modes[item.mode]}
              </Button>
              <Popover
                open={Boolean(anchorEls[item.stream_id])}
                anchorEl={anchorEls[item.stream_id]}
                onClose={() => handleMenuClose(item.stream_id)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <MenuItem onClick={() => changeMode(item.stream_id, 'Filter')}>Фильтр</MenuItem>
                <MenuItem onClick={() => changeMode(item.stream_id, 'Review')}>Модерация</MenuItem>
                <MenuItem onClick={() => changeMode(item.stream_id, 'Money')}>Контент</MenuItem>
                <MenuItem onClick={() => changeMode(item.stream_id, 'White')}>Белая страница</MenuItem>
              </Popover>
            </div>
          </Paper>
        ))}
      {!loading && streams.length === 0 && <Typography>Потоки не найдены</Typography>}
      <Pagination
        count={Math.ceil(total / streamsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color='primary'
        siblingCount={1}
        boundaryCount={1}
      />
    </>
  );
};
