import { useRef } from 'react';
import { TextField } from '@mui/material';

export const DebounceInput = (props) => {
  const { handleDebounce, debounceTimeout, ...other } = props;

  const timerRef = useRef(null);

  const handleChange = (event) => {
    if (!timerRef) {
      return;
    }

    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      handleDebounce(event.target.value);
    }, debounceTimeout);
  };

  return <TextField {...other} onChange={handleChange} variant='outlined' sx={{ width: '100%' }} />;
};
