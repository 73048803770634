import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import mainAPI from '../../utils/mainAPI';

/* ===================== FraudAnalysisForm ===================== */
const FraudAnalysisForm = () => {
  // Для формы анализа
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [eventNames, setEventNames] = useState('');
  const [additionalFields, setAdditionalFields] = useState('');
  const [csvData, setCsvData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Состояния для выбора модели и приложения
  const [models, setModels] = useState([]);
  const [selectedModelId, setSelectedModelId] = useState('');
  const [selectedAppId, setSelectedAppId] = useState('');

  // Загружаем список моделей при монтировании
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await mainAPI.get('/fraud');
        setModels(response.data);
      } catch (err) {
        console.error('Ошибка при загрузке моделей', err);
      }
    };
    fetchModels();
  }, []);

  // Функция для форматирования даты (гггг-мм-дд)
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    // Находим выбранную модель для получения токена
    const selectedModel = models.find((model) => model._id === selectedModelId);

    // Формируем данные запроса с выбранным appId и токеном как authorization
    const requestData = {
      appId: selectedAppId,
      authorization: selectedModel ? selectedModel.token : '',
      from_date: formattedFromDate,
      to_date: formattedToDate,
      event_names: eventNames,
      additional_fields: additionalFields,
    };

    try {
      const response = await fetch(
        process.env.NODE_ENV === 'development'
          ? `http://127.0.0.1:8000/api-flask/fraud-analysis`
          : `https://2leads.dev/api-flask/fraud-analysis`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const blob = await response.blob();
      const text = await blob.text();
      const parsedData = Papa.parse(text, { header: true });
      setCsvData(parsedData.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // При смене выбранной модели сбрасываем выбор приложения
  const handleModelChange = (e) => {
    setSelectedModelId(e.target.value);
    setSelectedAppId('');
  };

  return (
    <Box>
      <Typography variant='h4' gutterBottom>
        Fraud Analysis Form
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Селект для выбора модели */}
          <Grid item xs={12}>
            <TextField
              select
              label='Выберите модель'
              fullWidth
              value={selectedModelId}
              onChange={handleModelChange}
              required
            >
              {models.map((model) => (
                <MenuItem key={model._id} value={model._id}>
                  {model.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Селект для выбора приложения из выбранной модели */}
          <Grid item xs={12}>
            <TextField
              select
              label='Выберите приложение'
              fullWidth
              value={selectedAppId}
              onChange={(e) => setSelectedAppId(e.target.value)}
              required
              disabled={!selectedModelId}
            >
              {selectedModelId &&
                models
                  .find((model) => model._id === selectedModelId)
                  ?.apps.map((app, index) => (
                    <MenuItem key={index} value={app.appId}>
                      {app.name}
                    </MenuItem>
                  ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label='From Date'
              type='date'
              variant='outlined'
              fullWidth
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='To Date'
              type='date'
              variant='outlined'
              fullWidth
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Event Names'
              placeholder='e_rent_ftr_payed,....'
              variant='outlined'
              fullWidth
              value={eventNames}
              onChange={(e) => setEventNames(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Additional Fields'
              placeholder='fraud_reason,....'
              variant='outlined'
              fullWidth
              value={additionalFields}
              onChange={(e) => setAdditionalFields(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type='submit' variant='contained' color='primary' fullWidth disabled={loading}>
              {loading ? 'Processing...' : 'Get Fraud Analysis'}
            </Button>
          </Grid>
        </Grid>
      </form>

      <Box sx={{ marginTop: 2 }}>
        <Button
          variant='contained'
          color='secondary'
          sx={{ marginTop: 2 }}
          onClick={() => saveAs(new Blob([Papa.unparse(csvData)], { type: 'text/csv' }), 'fraud_analysis_result.csv')}
          disabled={!csvData}
          fullWidth
        >
          Download CSV Report
        </Button>
      </Box>

      {error && (
        <Typography color='error' sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}

      {csvData && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant='h6' gutterBottom>
            CSV Data Preview
          </Typography>
          <TableContainer component={Paper} sx={{ maxWidth: '1550px', overflowX: 'auto' }}>
            <Table sx={{ minWidth: 'max-content', tableLayout: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Count</TableCell>
                  {Object.keys(csvData[0]).map((key) => (
                    <TableCell key={key}>{key}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {csvData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    {Object.values(row).map((value, idx) => (
                      <TableCell key={idx}>{value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

/* ===================== ModelsManagement ===================== */
const ModelsManagement = () => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Состояния для создания новой модели
  const [newModelName, setNewModelName] = useState('');
  const [newModelToken, setNewModelToken] = useState('');

  // Состояния для модального окна добавления приложения
  const [appModalOpen, setAppModalOpen] = useState(false);
  const [selectedModelForModal, setSelectedModelForModal] = useState(null);
  const [modalAppName, setModalAppName] = useState('');
  const [modalAppId, setModalAppId] = useState('');

  // Состояния для редактирования модели
  const [modelEditModalOpen, setModelEditModalOpen] = useState(false);
  const [selectedModelForEdit, setSelectedModelForEdit] = useState(null);
  const [editModelName, setEditModelName] = useState('');
  const [editModelToken, setEditModelToken] = useState('');

  // Состояния для редактирования приложения
  const [appEditModalOpen, setAppEditModalOpen] = useState(false);
  const [selectedAppForEdit, setSelectedAppForEdit] = useState(null); // { modelId, originalAppId }
  const [editAppName, setEditAppName] = useState('');
  const [editAppId, setEditAppId] = useState('');

  // Получение моделей
  const fetchModels = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await mainAPI.get('/fraud');
      setModels(response.data);
    } catch (err) {
      setError('Ошибка при получении моделей');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchModels();
  }, []);

  // Создание новой модели
  const handleCreateModel = async () => {
    try {
      const response = await mainAPI.post('/fraud', {
        name: newModelName,
        token: `Bearer ${newModelToken}`,
      });
      setModels((prev) => [...prev, response.data]);
      setNewModelName('');
      setNewModelToken('');
    } catch (err) {
      setError('Ошибка при создании модели');
    }
  };

  // Открытие модального окна для добавления приложения
  const openAddAppModal = (modelId) => {
    setSelectedModelForModal(modelId);
    setModalAppName('');
    setModalAppId('');
    setAppModalOpen(true);
  };

  // Добавление приложения через модальное окно
  const handleAddApp = async () => {
    if (!selectedModelForModal || !modalAppName || !modalAppId) return;
    try {
      const response = await mainAPI.post(`/fraud/${selectedModelForModal}/apps`, {
        name: modalAppName,
        appId: modalAppId,
      });
      setModels((prev) => prev.map((model) => (model._id === selectedModelForModal ? response.data : model)));
      setAppModalOpen(false);
    } catch (err) {
      setError('Ошибка при добавлении приложения');
    }
  };

  // Удаление приложения из модели
  const handleDeleteApp = async (modelId, appId) => {
    try {
      const response = await mainAPI.delete(`/fraud/${modelId}/apps/${appId}`);
      setModels((prev) => prev.map((model) => (model._id === modelId ? response.data : model)));
    } catch (err) {
      setError('Ошибка при удалении приложения');
    }
  };

  // Удаление модели
  const handleDeleteModel = async (modelId) => {
    try {
      const response = await mainAPI.delete(`/fraud/${modelId}`);
      console.log('Delete response:', response);
      if (response.status === 200) {
        setModels((prev) => prev.filter((model) => model._id !== modelId));
      } else {
        setError('Не удалось удалить модель');
      }
    } catch (err) {
      console.error('Ошибка при удалении модели:', err);
      setError('Ошибка при удалении модели: ' + (err.response?.data?.message || err.message));
    }
  };

  // Открытие модального окна для редактирования модели
  const openEditModelModal = (model) => {
    setSelectedModelForEdit(model);
    setEditModelName(model.name);
    setEditModelToken(model.token.replace(/^Bearer\s+/i, '')); // удаляем "Bearer" для редактирования
    setModelEditModalOpen(true);
  };

  // Обновление модели
  const handleUpdateModel = async () => {
    if (!selectedModelForEdit) return;
    try {
      const response = await mainAPI.put(`/fraud/${selectedModelForEdit._id}`, {
        name: editModelName,
        token: `Bearer ${editModelToken}`,
      });
      setModels((prev) => prev.map((model) => (model._id === selectedModelForEdit._id ? response.data : model)));
      setModelEditModalOpen(false);
    } catch (err) {
      setError('Ошибка при обновлении модели');
    }
  };

  // Открытие модального окна для редактирования приложения
  const openEditAppModal = (model, app) => {
    setSelectedAppForEdit({ modelId: model._id, originalAppId: app.appId });
    setEditAppName(app.name);
    setEditAppId(app.appId);
    setAppEditModalOpen(true);
  };

  // Обновление приложения
  const handleUpdateApp = async () => {
    if (!selectedAppForEdit) return;
    try {
      const response = await mainAPI.put(
        `/fraud/${selectedAppForEdit.modelId}/apps/${selectedAppForEdit.originalAppId}`,
        {
          name: editAppName,
          appId: editAppId,
        }
      );
      setModels((prev) => prev.map((model) => (model._id === selectedAppForEdit.modelId ? response.data : model)));
      setAppEditModalOpen(false);
    } catch (err) {
      setError('Ошибка при обновлении приложения');
    }
  };

  return (
    <Box>
      <Typography variant='h5' gutterBottom>
        Models Management
      </Typography>
      {loading && <Typography>Загрузка моделей...</Typography>}
      {error && (
        <Typography color='error' gutterBottom>
          {error}
        </Typography>
      )}

      {/* Форма создания новой модели */}
      <Box sx={{ mb: 3 }}>
        <Typography variant='h6'>Создать новую модель</Typography>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={4}>
            <TextField label='Name' fullWidth value={newModelName} onChange={(e) => setNewModelName(e.target.value)} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Token'
              fullWidth
              value={newModelToken}
              onChange={(e) => setNewModelToken(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant='contained' onClick={handleCreateModel} startIcon={<AddIcon />} fullWidth>
              Создать модель
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Список моделей в виде аккордеона */}
      {models.map((model, index) => (
        <Accordion key={model._id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Typography variant='subtitle1'>
                {index + 1}. {model.name}
              </Typography>
            </Box>
            <IconButton
              variant='outlined'
              color='primary'
              size='small'
              onClick={(e) => {
                e.stopPropagation();
                openEditModelModal(model);
              }}
              sx={{ marginLeft: 'auto', mr: 1 }}
            >
              <ModeEditIcon />
            </IconButton>
            <IconButton
              variant='outlined'
              color='error'
              size='small'
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteModel(model._id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            {model.apps && model.apps.length > 0 ? (
              <Box>
                {model.apps.map((app, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mb: 1,
                      p: 1,
                      border: '1px solid #ddd',
                      borderRadius: 1,
                    }}
                  >
                    <Typography>
                      {app.name} (ID: {app.appId})
                    </Typography>
                    <Box>
                      <IconButton
                        variant='outlined'
                        color='primary'
                        size='small'
                        onClick={() => openEditAppModal(model, app)}
                        sx={{ mr: 1 }}
                      >
                        <ModeEditIcon />
                      </IconButton>
                      <IconButton
                        variant='outlined'
                        color='error'
                        size='small'
                        onClick={() => handleDeleteApp(model._id, app.appId)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography variant='body2'>Нет приложений</Typography>
            )}
            <Button
              variant='contained'
              size='small'
              onClick={() => openAddAppModal(model._id)}
              sx={{ mt: 1 }}
              startIcon={<AddIcon />}
            >
              Добавить приложение
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}

      {/* Модальное окно для добавления приложения */}
      <Dialog open={appModalOpen} onClose={() => setAppModalOpen(false)}>
        <DialogTitle>Добавить приложение</DialogTitle>
        <DialogContent>
          <TextField
            label='App Name'
            fullWidth
            value={modalAppName}
            onChange={(e) => setModalAppName(e.target.value)}
            sx={{ my: 1 }}
          />
          <TextField
            label='App ID'
            fullWidth
            value={modalAppId}
            onChange={(e) => setModalAppId(e.target.value)}
            sx={{ my: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAppModalOpen(false)}>Отмена</Button>
          <Button onClick={handleAddApp} variant='contained'>
            Добавить
          </Button>
        </DialogActions>
      </Dialog>

      {/* Модальное окно для редактирования модели */}
      <Dialog open={modelEditModalOpen} onClose={() => setModelEditModalOpen(false)}>
        <DialogTitle>Редактировать модель</DialogTitle>
        <DialogContent>
          <TextField
            label='Name'
            fullWidth
            value={editModelName}
            onChange={(e) => setEditModelName(e.target.value)}
            sx={{ my: 1 }}
          />
          <TextField
            label='Token'
            fullWidth
            value={editModelToken}
            onChange={(e) => setEditModelToken(e.target.value)}
            sx={{ my: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModelEditModalOpen(false)}>Отмена</Button>
          <Button onClick={handleUpdateModel} variant='contained'>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>

      {/* Модальное окно для редактирования приложения */}
      <Dialog open={appEditModalOpen} onClose={() => setAppEditModalOpen(false)}>
        <DialogTitle>Редактировать приложение</DialogTitle>
        <DialogContent>
          <TextField
            label='App Name'
            fullWidth
            value={editAppName}
            onChange={(e) => setEditAppName(e.target.value)}
            sx={{ my: 1 }}
          />
          <TextField
            label='App ID'
            fullWidth
            value={editAppId}
            onChange={(e) => setEditAppId(e.target.value)}
            sx={{ my: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAppEditModalOpen(false)}>Отмена</Button>
          <Button onClick={handleUpdateApp} variant='contained'>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const FraudManagementTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label='Fraud Analysis' />
        <Tab label='Models Management' />
      </Tabs>
      <Box sx={{ p: 2 }}>
        {tabIndex === 0 && <FraudAnalysisForm />}
        {tabIndex === 1 && <ModelsManagement />}
      </Box>
    </Box>
  );
};

export default FraudManagementTabs;
