import React, { useEffect, useState } from 'react';
import { Button, CardContent, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import geo from '../../../assets/geo';
import timezones from '../../../assets/timezones';
import dates from '../../../assets/dates';
import mainAPI from '../../../utils/mainAPI';
import DatePicker from 'react-date-picker';

function AddCapItem(props) {
  const [selectedNetwork, setSelectedNetwork] = useState(0);
  const [selectedGeo, setSelectedGeo] = useState(0);
  const [selectedTimezone, setSelectedTimezone] = useState(0);
  const [selectedDate, setSelectedDate] = useState(0);
  const [includeWord, setIncludeWord] = useState('');
  const [additionalText, setAdditionalText] = useState('');
  const [limit, setLimit] = useState(null);
  const [datePickerDate, setDatePickerDate] = useState(new Date());
  const [customDate, setCustomDate] = useState('');

  const [geoStatus, setGeoStatus] = useState(false);
  const [geoName, setGeoName] = useState('');
  const [listDelete, setListDelete] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  let geoList = geo;

  function makeNetworkMenuItems() {
    const result = [];
    props.networks.forEach((item) => {
      result.push(
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      );
    });
    return result;
  }

  function send() {
    const allData = {
      network: selectedNetwork,
      geo: selectedGeo === 'Global' ? 1 : selectedGeo,
      dates: selectedDate,
      customDates: customDate,
      timezone: selectedTimezone,
      limit: limit,
      keyword: includeWord,
      text: additionalText,
      enabled: true,
      status: 0,
    };
    mainAPI.post('/bot/addrule', allData).then((res) => {
      props.close();
    });
  }

  function changeDate(date) {
    setDatePickerDate(date);
    const formatMap = {
      mm: date.getMonth() + 1,
      dd: date.getDate(),
      yy: date.getFullYear().toString().slice(-2),
      yyyy: date.getFullYear(),
    };
    const result =
      formatMap.yyyy +
      '-' +
      (formatMap.mm < 10 ? '0' + formatMap.mm : formatMap.mm) +
      '-' +
      (formatMap.dd < 10 ? '0' + formatMap.dd : formatMap.dd);
    setCustomDate(result);
  }

  function setDate(e) {
    setSelectedDate(e.target.value);
    if (selectedDate !== 12) {
      setCustomDate('');
    }
  }

  const onClickCreateGeo = async () => {
    try {
      await mainAPI.post('/bot/geo-create', {
        name: geoName,
      });

      setGeoStatus(false);
      setSelectedGeo(geoName);
      geoList.push(geoName);
      setGeoName('');
    } catch (e) {
      setErrorMessage(e.response.data.error);
    }
  };

  const clickDelete = async () => {
    await mainAPI.post('/bot/geo-delete', {
      name: selectedGeo,
    });
    listDelete.push(selectedGeo);
    setSelectedGeo(0);
  };

  return (
    <CardContent>
      <Box sx={{ p: 1 }}>
        <Typography sx={{ m: 2, textAlign: 'center' }} variant='h5'>
          Добавление капа
        </Typography>
        <FormControl fullWidth>
          <InputLabel id='network-label'>Партнёрка:</InputLabel>
          <Select
            value={selectedNetwork}
            fulllWidth
            onChange={(e) => {
              setSelectedNetwork(e.target.value);
            }}
            labelId='network-label'
            label='Партнёрка'
          >
            <MenuItem value={0}>Нет</MenuItem>
            {makeNetworkMenuItems()}
          </Select>
        </FormControl>
      </Box>
      {geoStatus ? (
        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
          <FormControl fullWidth>
            {errorMessage && <p style={{ margin: 0 }}>{errorMessage}</p>}
            <TextField
              required
              margin='dense'
              label='Код Геолокации'
              type='text'
              multiline
              fullWidth
              value={geoName}
              onChange={(e) => {
                setGeoName(e.target.value.replace(/^\s+|\s{2,}$/g, ''));
              }}
            />
          </FormControl>
          <Box sx={{ display: 'flex', marginTop: 1, justifyContent: 'space-between' }}>
            <Button
              variant='outlined'
              color='success'
              size='small'
              disabled={geoName === ''}
              onClick={() => onClickCreateGeo()}
            >
              Сохранить
            </Button>
            <Button variant='outlined' color='error' size='small' onClick={() => setGeoStatus(false)}>
              Отменить
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
          <FormControl fullWidth>
            <InputLabel id='GEO-label'>Гео:</InputLabel>
            <Select
              value={selectedGeo}
              style={{ minWidth: 100 }}
              disabled={selectedNetwork === 0}
              onChange={(e) => {
                setSelectedGeo(e.target.value);
              }}
              labelId='GEO-label'
              label='Гео'
            >
              <MenuItem value={0}>Нет</MenuItem>
              {geoList
                .filter((item) => !listDelete.includes(item))
                .map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', marginTop: 1, justifyContent: 'space-between' }}>
            <Button
              variant='outlined'
              color='success'
              size='small'
              disabled={selectedNetwork === 0}
              onClick={() => {
                setGeoStatus(true);
                setGeoName('');
                setErrorMessage('');
              }}
            >
              Создать
            </Button>
            <Button
              variant='outlined'
              color='error'
              size='small'
              disabled={selectedGeo === 0 || selectedNetwork === 0}
              onClick={() => clickDelete()}
            >
              Удалить
            </Button>
          </Box>
        </Box>
      )}

      <Box sx={{ p: 1 }}>
        <FormControl fullWidth>
          <InputLabel id='timezone-label'>Часовой пояс:</InputLabel>
          <Select
            value={selectedTimezone}
            style={{ minWidth: 100 }}
            disabled={selectedGeo === 0}
            onChange={(e) => {
              setSelectedTimezone(e.target.value);
            }}
            labelId='timezone-label'
            label='Часовой пояс'
          >
            <MenuItem value={0}>Нет</MenuItem>
            {timezones.map((item) => {
              return (
                <MenuItem key={'T' + item} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ p: 1 }}>
        <FormControl fullWidth>
          <InputLabel id='dates-label'>Интервал:</InputLabel>
          <Select
            value={selectedDate}
            style={{ minWidth: 100 }}
            disabled={selectedTimezone === 0}
            onChange={(e) => {
              setDate(e);
            }}
            labelId='dates-label'
            label='Интервал'
          >
            <MenuItem value={0}>Нет</MenuItem>
            {dates.map((item, index) => {
              return (
                <MenuItem key={'D' + item[1]} value={item[1]}>
                  {item[0]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      {selectedDate === 12 ? (
        <Box sx={{ p: 1 }}>
          <DatePicker value={datePickerDate} onChange={changeDate} />
        </Box>
      ) : null}

      <Box sx={{ p: 1 }}>
        <TextField label='Лимит' type='number' value={limit} fullWidth onChange={(e) => setLimit(e.target.value)} />
      </Box>
      <Box sx={{ p: 1 }}>
        <TextField
          style={{ width: '100%' }}
          placeholder='FR'
          label='Содержит слово'
          value={includeWord}
          onChange={(e) => setIncludeWord(e.target.value)}
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <TextField
          style={{ width: '100%' }}
          placeholder='@test'
          label='Дополнительный текст'
          value={additionalText}
          onChange={(e) => setAdditionalText(e.target.value)}
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='contained'
          color='primary'
          size='large'
          disabled={limit == null || limit <= 0 || selectedDate === 0}
          onClick={send}
        >
          Cохранить
        </Button>
      </Box>
    </CardContent>
  );
}

export default AddCapItem;
