import mainAPI from '../utils/mainAPI';

const geo = [];

const fetchGeo = async () => {
  try {
    const response = await mainAPI.get('/bot/geo');
    const names = response.data?.map((item) => item.name);
    geo.push(...names);
  } catch (error) {
    console.error('Ошибка загрузки геоданных:', error);
  }
};

await fetchGeo();

export default geo;
